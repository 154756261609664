.loginPage {
  .mainContainer{
    display: flex;
    .LoginContainer{
      width: 50%;
      display: flex;
      justify-content: end;
      align-items: center;
        .loginWrapper{
          display: flex;
          flex-direction: column;
          align-items: center;
          .innerContainer{
            position: relative;
            flex-direction: column;
            min-height: 200px;
            width: 380px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0px solid white;
            padding: 15px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.2); margin:3rem 0 0;
            h1{
              position: absolute;
              top: 10px;
              color: white;
              font-weight: 600;
            }
            #loginText{
              position: absolute;
              top: 80px;
              color: white;
              font-weight: 600;
              font-size: 1.25rem;
            }
          }
          .copyright{
            color: white;
            min-width: 477px;
          }
        }
    }
    #EstimationsText{
      color: white;
      font-weight: 700;
      margin: 0px;
    }
    .descriptionDiv{
      display: flex;
      flex-direction: column;
      span{
        color: #fff;
        text-align: left;
        display: block;
        font-size: 30px;
      }
    }
  }
  /* PAGE HEADER CSS  */
  .mainLogo {position: absolute; top:20px; right: 60px; width: 140px; display: block;}

  .navbar.scrolling-navbar {padding: 30px 50px}
  #navbarCollapse3 {display: none!important}
  .navbar.scrolling-navbar {background: none!important; box-shadow: none}
  
  /* PAGE BODY CSS  */  
  .loginBox .MuiPaper-root a {color: #fff}
  .loginBox .MuiPaper-root {box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12); 
    margin-right:60px; width: 380px;
    margin-right: 60px;
    float: right; text-align: left;padding:40px 30px 10px; background-color: rgba(255, 255, 255, .2); 
    color: #fff; position: relative;}
    .loginBox .MuiPaper-root .md-form label {display: none}
    .form-display-info {margin-bottom:25px}
    .form-group {margin-bottom:25px}
    .form-title {text-align: left; font-weight: bold}
    .contentBox  span {color: #fff; text-align: left; display: block; font-size: 30px; padding-right: 50%}
    span.text-danger {font-size: 12px}
     h2 {font-size:62px; font-weight:bold; color: #fff; width: 40%; text-align: left; padding-top:40px}
    button.btn-primary {width: 100%; background:#5A0480!important; color: #fff; font-size: 18px; padding: 8px }
    .col-md-6 .form-group {margin: 0}
    .new-user, .forgot-password {font-size: 12px; text-decoration: underline}
    .forgot-password {text-align: right}
    .form-check {position: relative; top:-2px}
    .form-check label {color: #fff!important; font-size: 12px}
    .new-user:hover, .forgot-password:hover {text-decoration: none}
    .copyRight {color: #fff; position: absolute; bottom: -50px; left:20px; font-size: 12px}
    .md-form.md-outline input {background: #f2f2f2!important}
    .MuiSnackbar-anchorOriginBottomCenter .MuiAlert-standardError {background: #fff!important; color: #000; padding: 10px!important; width: 300px!important}
    .MuiSnackbar-anchorOriginBottomCenter .MuiAlert-standardError .MuiAlert-message {color: #000}
    .MuiSnackbar-anchorOriginBottomCenter {position: fixed!important; top:10px!important; align-items: baseline}
    .MuiPaper-root {align-self: baseline; align-items: baseline}  
  }
  .micrsoftimg {
    height:20px;
    width:20px;
    margin-right:5px
  }
  