.App {
  text-align: center;
}
body{
  background-color: #cba3d554;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiButton-root.MuiButton-containedPrimary{
  background-color: #5A047F; box-shadow:0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.MuiButton-root.MuiButton-containedPrimary:hover{
  background-color: #5A047F; box-shadow:0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-container{
  margin-top: 125px;
    padding: 0 15px;
}
.main-container .alert {
  position: absolute;
  bottom: 0;
  right: 0;
}

body {background-image: url(assets/img/loginBg.png);
  background-position: top;
  background-repeat  : no-repeat;
  background-size    : cover;
  background-color: #F1F1F1; color: #000
}
.my-custom-nav-bar .branding b {color: #fff!important}
thead, thead tr, thead th, .MTableHeader-header-13 {background:#EDEDED!important}
.estimate-table  tr .section-head {background: #EDEDED!important; color: #000}
.estimate-table  tr .section-head {background: #EDEDED!important; color: #000}
.estimate-table .task th {background: #fff!important; color: #000!important; padding-top:5px; padding-bottom:5px}
.tabpanel .estimate-table tr input {color:#000!important}
.main-div {margin-bottom: 20px;}
.summTble table {width:100% !important}
.navbar.scrolling-navbar.top-nav-collapse, .my-custom-nav-bar {background-image: url(assets/img/loginBg.png);
  background-position: top;
  background-repeat  : no-repeat;
  background-size    : cover;
  background-color: #F1F1F1}
  .branding img {display: none;}
  #navbarCollapse3 .navbar-nav.ml-auto a {color: #fff!important}
  .iconClass:hover {cursor: pointer; opacity: 0.7}
  .MTableToolbar-searchField-10 {border:1px solid #5A0480!important; border-radius:5px}
  .MTableToolbar-searchField-10 .MuiInput-underline:before {border: 0!important}
  .navbar #navbarCollapse3 .dropdown-menu a {color: #000!important; font-size: 14px}
  .MuiToolbar-gutters {padding-left: 14px!important}
  .MuiToolbar-gutters h6 {text-transform: capitalize!important; font-size: 16px}
 .MuiTableCell-body {font-size: 14px!important}
 .MuiToolbar-regular {min-height: 44px!important}
 .MuiTableCell-body .MuiIcon-root  {font-size: 1.2rem}
 .singleMidBox {background: #fff; border-radius: 5px; width: 40%; margin:10% auto; min-height: 150px; padding-top: 50px; padding-bottom: 50px}
 .main-container#estimate {margin-top: 75px!important; }
 .forgotPage .form {padding:20px}
 #sdlc .MuiTable-root tr:nth-last-child(-n+5) {
  font-weight: bold;
}
#sdlc .MuiTable-root tr:last-child,#sdlc .MuiTable-root tr:nth-last-child(3),#sdlc .MuiTable-root tr:nth-last-child(5) {
  background-color: #66339952
}

.tabBox {max-height:48vh; overflow-y: auto; margin-bottom: 10px; border:1px solid #ccc}
@media only screen and (min-width: 1400px) {
  .tabBox {max-height:60vh; overflow-y: auto; margin-bottom: 10px; border:1px solid #ccc}
}
#assumptions table th:first-child, #assumptions table td:first-child,
#sdlc table th:first-child, #sdlc table td:first-child 
{display: none;}

#assumptions table th:nth-child(2) {width:5%!important}
#assumptions table th:nth-child(3) {width:88%!important}
#assumptions table th:nth-child(4) {width:35%!important}

#assumptions table td:nth-child(2) {width:5%!important}
#assumptions table td:nth-child(3) {width:55%!important}
#assumptions table td:nth-child(4) {width:35%!important}
#assumptions table td:nth-child(4) div {justify-content: center}

.navbar {padding-right:70px !important;}

#summary .tabBox {overflow-x:hidden;}

.textOnSelect {position: relative; width:100%; margin:7rem auto .5rem;}
.textOnSelect label {position: absolute; top: -13px; left:10px; padding: 2px; z-index: 1;}
.textOnSelect label:after {content: " "; background-color: #fff; width: 100%; height:15px; position: absolute; left: 0; bottom: 0; z-index: -1;}
.textOnSelect label {font-weight: 500; margin-bottom: .5rem; font-size:13px;}
.textOnSelect .form-control {box-shadow: none !important;}