
 .my-brand{
    width:200px !important;
}
.my-custom-nav-bar{
    background-color: #fff !important;
    color:#000;
   
    .branding{
        color:red;
        b{color:#000}
    }
    .navbar-nav{
        .nav-item{
            color:#000;
        }
    }
 .nav-link.Ripple-parent.active{
     border-bottom: 2px solid #571c7c;
 }
 .nav-link.Ripple-parent{
    border-bottom: 2px solid transparent;
}
}
.navbar.navbar-dark .breadcrumb .nav-item .nav-link, .navbar.navbar-dark .navbar-nav .nav-item .nav-link{
    color:#000 !important;
    padding: 0px 20px;
}

.navbar.navbar-dark .breadcrumb .nav-item:hover .nav-link, .navbar.navbar-dark .navbar-nav .nav-item:hover .nav-link{
    color:red !important;
}
.branding{
    cursor: pointer;
}

.footerButtons{
    display: block !important;
}