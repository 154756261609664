// .main {
//     margin: 0 auto;
//     display: block;
//     height: 100%;
//     margin-top: 60px;
// }
// .mainInner{
//     display: table;
//     height: 100%;
//     width: 100%;
//     text-align: center;
// }
// .mainInner div{
//     display:table-cell;
//     vertical-align: middle;
//     font-size: 3em;
//     font-weight: bold;
//     letter-spacing: 1.25px;
// }
// #sidebarMenu {
//     height: 100%;
//     position: relative;
//     right: 0;
//     width: 100%;
//     transform: translateX(250px);
//     transition: transform 250ms ease-in-out;
//     background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);
// }
// .sidebarMenuInner{
//     margin:0;
//     padding:0;
//     border-top: 1px solid rgba(255, 255, 255, 0.10);
// }
// .sidebarMenuInner li{
//     list-style: none;
//     color: #fff;
//     text-transform: uppercase;
//     font-weight: bold;
//     padding: 20px;
//     cursor: pointer;
//     border-bottom: 1px solid rgba(255, 255, 255, 0.10);
// }
// .sidebarMenuInner li span{
//     display: block;
//     font-size: 14px;
//     color: rgba(255, 255, 255, 0.50);
// }
// .sidebarMenuInner li a{
//     color: #fff;
//     text-transform: uppercase;
//     font-weight: bold;
//     cursor: pointer;
//     text-decoration: none;
// }
// input[type="checkbox"]:checked ~ #sidebarMenu {
//     transform: translateX(0);
// }

// input[type=checkbox] {
//     transition: all 0.3s;
//     box-sizing: border-box;
//     display: none;
// }
// .sidebarIconToggle {
//     transition: all 0.3s;
//     box-sizing: border-box;
//     cursor: pointer;
//     position: absolute;
//     z-index: 99;
//     height: 100%;
//     width: 100%;
//     top: 22px;
//     right: 15px;
//     height: 22px;
//     background-color: #000;
//     width: 22px;
// }
// .spinner {
//     transition: all 0.3s;
//     box-sizing: border-box;
//     position: absolute;
//     height: 3px;
//     width: 100%;
//     background-color: #fff;
// }
// .horizontal {
//     transition: all 0.3s;
//     box-sizing: border-box;
//     position: relative;
//     float: left;
//     margin-top: 3px;
// }
// .diagonal.part-1 {
//     position: relative;
//     transition: all 0.3s;
//     box-sizing: border-box;
//     float: left;
// }
// .diagonal.part-2 {
//     transition: all 0.3s;
//     box-sizing: border-box;
//     position: relative;
//     float: left;
//     margin-top: 3px;
// }
// input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
//     transition: all 0.3s;
//     box-sizing: border-box;
//     opacity: 0;
// }
// input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
//     transition: all 0.3s;
//     box-sizing: border-box;
//     transform: rotate(135deg);
//     margin-top: 8px;
// }
// input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
//     transition: all 0.3s;
//     box-sizing: border-box;
//     transform: rotate(-135deg);
//     margin-top: -9px;
// }


#layout {
    display: flex; // Flexbox definition
    overflow: hidden; // Disable scrolling on layout
  
    // // Style in common for sidebars and main area:
    // .header {
    //   position: relative;
    //   height: var(--header-height);
    //   text-align: center; // Horizontal center
    //   .title { position: absolute; left: 0; right: 0; }
    // }
    .content {
     
      box-sizing: border-box;
     
      flex-grow: 1;
    
    }
  }
  
 
  
  // Style in common for both sidebars:
     #right{ .icon { position: absolute;
        top: 0px;
        right: 20px;
        width: 30px;
        height: 30px;
        font-size: 40px;
        text-align: center;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer; }
     }
  .open{ width:250px;padding-left: 20px;}
  .closed{ display: none; }