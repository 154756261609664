
 #projectName, #clientName {
    color:black !important;
    border: 1px solid black
}
.estimate-table {
    .section tr .section-head {padding-top:0; padding-bottom: 0 }
     .section tr .section-head .form-control {
        color: #FFF;
        font-family: "museo",sans-serif;
        font-style: normal;
        font-weight: 100;
        font-size: 24px;
        line-height: 26px;
        width: 100px;
        min-width: 100px;
        max-width: 500px;
        padding: 0px 8px;
    }
   
    .table.section .colorblock {
      
    .red { background: #f7464a;}
    .turquoise {
        background: #46bfbd;
    }
    .yellow {
        background: #fdb45c;
    } 
    .green {
        background: #81c567;
    } 
    .purple {
        background: #af71be;
    }
   .blue {
        background: #27a7f8;
    }
   span {
        display: inline-block;
       
        width: 15px;
        height: 15px;
        border: solid 1px #FFF;
        box-sizing: border-box;
        border-radius: 50%;
        margin-right: 4px;
   }
    }
    .section-expand-collapse{
        text-align: right;
        &:hover{
            background-color: #ddd;
        }
    }

.table {
    width: 100%;
    margin-bottom: 0.5rem;
    color: #212529;
}
 .color-blue {
     tr .section-head,  tr .section-total, tr .section-expand-collapse {
        background: #27a7f8;
        color:#fff !important;
    }
   .task .task-head,.task .task-space,  .task .task-total, .task .task-expand-collapse {
        background: #72c6fa;
        color:#fff !important;
    }
}
.color-yellow {
    tr .section-head,  tr .section-total, tr .section-expand-collapse {
    background: #fdb45c;
    color:#fff !important;
}
.task .task-head, .task .task-space, .task .task-total, .task .task-expand-collapse {
    background: #ffd097;
    color:#fff !important;
}
}
    input{
        border: none;
        background: transparent;

        font-weight: bold;
        &:focus{
border:1px solid #ddd !important;
border-color:#ddd !important;
        }
    }  
     .assignment:last-child td {
        border-bottom: 0px;
    }
    
     .assignment td {
        font-family: "museo",sans-serif;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        padding: 5px 5px;
        border-top: 0px;
        border-bottom: 1px solid #ddd;
    }
    .chosen-container {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        zoom: 1;
        *display: inline;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }
    .assignment-name-select {
        font-family: museo, sans-serif;
        font-size: 16px;
        -webkit-appearance: none;
        padding-left: 10px;
        padding-right: 40px;
        border: 0px;
        background: transparent;
    }

    .action-icons{
        text-decoration: none;
        cursor: pointer;      
        opacity: .5;     
    }
    .section-head:hover,.task-head:hover,.assignment-head:hover{
        .action-icons{          
            opacity: 1;
            display: inline-block;
         
    
        }
    }
    .assignment{
        input{
            color:#000 !important;
        }
    }
  
}
.section-expand-collapse:hover{
    cursor: pointer;
}
.MuiGrid-spacing-xs-2 {color: #fff!important}
.MuiGrid-spacing-xs-2 input {background: #fff}
.MuiFormGroup-root, .MuiFormControlLabel-root {display: block!important;}
.MuiFormControlLabel-root {float: left;}
.colorblock, .change-color {display: none;}
.table.section, .table.task {table-layout: fixed;width:100%}

.table.section th:nth-child(1), .table.task th:nth-child(1) {width: 55%}
.table.section th:nth-child(1) span.section-name input, .table.task th:nth-child(1) span.task-name input {width: 100%!important}
.table.section th:nth-child(2), .table.task th:nth-child(2) {width: 7%}
.table.section th:nth-child(3), .table.task th:nth-child(3) {width: 14%}
.table.section th:nth-child(4), .table.task th:nth-child(4) {width: 14%}
.table.section th:nth-child(5), .table.task th:nth-child(5) {width: 12%}
.table.section th:nth-child(6), .table.task th:nth-child(6) {width: 0%}
