.main-container.homePage  {margin-top: 85px}
.homePage {
 

.MuiTableBody-root span.material-icons {margin:0 5px!important}
.MuiTableBody-root span[title=" Export to Excel"] {position: relative; top: -6px}
.MuiTableBody-root span[title=" Export to Excel"] .icnExport {font-size: 27px}
.MuiTableBody-root tr td:nth-child(6) span:first-child {margin-left: 0!important; margin-right: 0!important}

.analytics-section .MuiPaper-rounded {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12); 
 background-color: rgba(255, 255, 255, .2)!important; color: #fff; padding: 10px 20px
}
.icn {margin-right: 10px; border-right: 1px solid #fff; padding-right: 20px}
.analytics span:nth-child(2) {font-size: 32px; font-weight: bold}
.analytics span:nth-child(3) {font-size: 12px; width: 50px; display: inline-block; text-align: left; padding-left: 10px; position: relative; top: 3px}
.MuiToolbar-gutters {position: relative;}
.MuiToolbar-gutters .MTableToolbar-searchField-10, .MuiToolbar-gutters .MuiTextField-root {position: absolute;}
.MuiToolbar-gutters .MuiButton-containedPrimary {position: absolute; right:20px; top: 15px; background: #5A047F}
.MuiToolbar-gutters .gridHeading {font-weight: bold; font-size: 18px; text-transform: capitalize; color: #5A047F}
.MuiTable-root {margin:0 20px; width: 97%!important}
.analytics-section {overflow: hidden; margin-bottom: 20px;}
.icnR {margin-right: 10px}
.MuiToolbar-regular {min-height: 64px!important}
.MuiToolbar-gutters {padding-left: 24px!important}
.MuiTableBody-root select {border-radius: 5px; padding: 3px 5px}
.MuiTableCell-sizeSmall {padding: 5px}
}

.backdrop {
   
}