.total-area .total-low{
    background-color: #999ba3;
}

.total-area .average-total{
    background-color: #7a7d86;
}

.total-area{
    margin-top: 33px;
}
.total-area .high-total{
    background-color: #4c4f5a;
}

.total-low, .total-mean, .total-high{
    color: #dddddd;
    font-size: 55px

}
.total-block{
    padding: 10px;
}
.section-total{
    text-align: right;
}
.total-area .low-total {
    background: #999ba3;
}
.total-area h4 {
    font-size: 18px;
    margin: 0px 0px 2px;
    color: #fff;
}

.section>tbody{
    border-bottom: 2px solid purple;
}
