.registerPage.main-container {margin-top:90px}
.registerPage {
    
    /* PAGE HEADER CSS  */
    .mainLogo {position: absolute; top:20px; right: 60px; width: 140px; display: block;}
    label {color: #fff!important; margin-bottom: 0}
    .navbar.scrolling-navbar {padding: 30px 50px}
    #navbarCollapse3 {display: none!important}
    .navbar.scrolling-navbar {background: none!important; box-shadow: none}
    
    /* PAGE BODY CSS  */  
    .MuiPaper-root a {color: #fff}
    .MuiPaper-root {box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12); 
      width: 480px;
      
      float: right; text-align: left;padding:30px 30px; background-color: rgba(255, 255, 255, .2); 
      color: #fff; position: relative;}
      .MuiPaper-root .md-form label {display: none}
      .form-display-info {margin-bottom:5px}
      .form-group {margin-bottom:10px}
      .form-title {text-align: left; font-weight: bold}
      .contentBox  span {color: #fff; text-align: left; display: block; font-size: 30px}
       h2 {font-size:52px; font-weight:bold; color: #fff; width: 40%; text-align: left}
      button.btn-primary {width: 100%; background:#5A0480!important; color: #fff; font-size: 18px; padding: 8px }
      .col-md-6 .form-group {margin: 0}
      .new-user, .forgot-password {font-size: 12px; text-decoration: underline}
      .forgot-password {text-align: right}
      .form-check {position: relative; top:-2px}
      .form-check label {color: #fff!important; font-size: 12px}
      .new-user:hover, .forgot-password:hover {text-decoration: none}
      .copyRight {color: #fff; position: absolute; bottom: -50px; left:20px; font-size: 12px}
      .md-form.md-outline input {background: #f2f2f2!important}
      .MuiSnackbar-anchorOriginBottomCenter .MuiAlert-standardError {background: #fff!important; color: #000; padding: 10px!important; width: 300px!important}
      .MuiSnackbar-anchorOriginBottomCenter .MuiAlert-standardError .MuiAlert-message {color: #000}
      .MuiSnackbar-anchorOriginBottomCenter {position: fixed!important; top:10px!important; align-items: baseline}
      .loginBox .MuiPaper-root {align-self: baseline; align-items: baseline}  
    }
    
  