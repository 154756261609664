table.table th, table.table td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.checkbox{
    font-size: 0.6rem !important;
}
.table-datatable__root{
    border: 1px solid #ddd;
    .taskname{
        width:300px
    }
}
.grid-main-div .f1-accordian {margin:0 0 .5rem;}
.grid-main-div .f1-accordian .Collapsible__trigger {background:#5A047F; padding:.65rem 1rem .5rem .85rem;}
.grid-main-div .f1-accordian .Collapsible__trigger .heading {color:#fff; font-weight:500;}
.grid-main-div .f1-accordian .Collapsible__trigger .heading .fa {float:right;}
.grid-main-div .f1-accordian .Collapsible__contentInner {padding:.5rem;}


.grid-main-div .widHeightDivScroll { width: 100%; overflow: auto; max-height: 40vh;}
.grid-main-div .widHeightDivScroll{ 
    .sprintEditableCapacity{
        width: 65px;
        display: inline;
        position: relative;
        left: 12%;
        margin-left: 15px;
    }
}
.grid-main-div table {margin: 0; border: none; border-collapse: separate; border-spacing: 0; table-layout: fixed;}
.grid-main-div table .thead {position:-webkit-sticky; position: sticky; vertical-align:middle; text-align:center; z-index:5; border-bottom: 1px solid #dee2e6;}
.grid-main-div table .thead-th {position: sticky; top: 0; background-color:#EDEDED; width:82px}
.grid-main-div table th, .grid-main-div table td {position:-webkit-sticky; position: sticky; vertical-align:middle; text-align:center;}
.grid-main-div table td.thead-th:nth-child(1) {width:150px; min-width:150px; max-width:150px; left:0; background-color:#EDEDED; z-index:2;}
.grid-main-div table td.thead-th:nth-child(2) {width:150px; min-width:150px; max-width:150px; left:150px; background-color:#EDEDED; z-index:2;}
.grid-main-div table td:nth-child(1) {width:150px; min-width:150px; max-width:150px; left:0; background-color:#fff; z-index:2;}
.grid-main-div table td:nth-child(2) {width:150px; min-width:150px; max-width:150px; left:150px; background-color:#fff; z-index:2;}
.grid-main-div table thead .thead-th {white-space:nowrap;}
.grid-main-div input[type="checkbox"] {height:13px;}

.widHeightDivScroll::-webkit-scrollbar {width:7px; height:6px;}
/* Track */
.widHeightDivScroll::-webkit-scrollbar-track {}
/* Handle */
.widHeightDivScroll::-webkit-scrollbar-thumb {-webkit-border-radius:50px; border-radius:50px; background:#bdbdbd;}
.widHeightDivScroll::-webkit-scrollbar-thumb:window-inactive {}

.matrixHeadDiv {line-height:2.3rem; font-size:.92rem;}
.matrixHeadDiv .labelDiv {width:19rem; float:left; text-align:left;}
.matrixHeadDiv .matrixInput {float:left; width:105px; margin-right:10px; font-size:.90rem;}
.matrixHeadDiv .noinputBx {float:left; width:130px; margin-right:10px; font-size:.90rem;}
.matrixHeadDiv button[type="button"] {margin-left:10px; padding:5px 15px !important}
.matrixHeadDiv .textFullDiv {float:left; margin-right:10px;}

