#estimate {
    background: #fff; padding-top:10px; border-radius: 5px; margin-right: 5px; margin-left: 5px; min-height: 450px; position: relative;
    .nav-tabs .nav-item.show .nav-link, #estimate .nav-tabs .nav-link.active {background: none!important; margin-bottom: 20px; font-weight: bold}
 .nav-tabs a {color: #666!important}
.nav-tabs {margin-bottom: 20px}
 .main-div {margin-bottom: 0; width:auto; margin:10px}
.main-div:last-child {margin-bottom: 20px}
button.MuiButton-containedPrimary {margin-bottom: 20px; padding: 5px 35px}
.nav-tabs .nav-link.active {color:#5A0480!important; border-color:#5A0480!important; font-weight: bold }
.MuiTable-root {border-radius: 0}
.MuiGrid-spacing-xs-2, .average-total.total-block {background-image: url(../../assets/img/loginBg.png);
    background-position: top;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-color: #F1F1F1; color: #000; border-radius: 5px 5px 0 0 }
    .total-area.affix-top {margin: 0;margin-bottom:5px}
    .MuiGrid-grid-xs-12.MuiGrid-grid-sm-3 {padding-top: 5px!important}
    .MuiGrid-spacing-xs-2 {padding:10px; border-radius: 5px; margin-bottom: 20px!important}
canvas {padding-top: 20px; padding-bottom: 20px;; box-shadow: 0px 0px 16px #A8A2A229; width: 100%; }
table {margin:10px; width:auto}
.table.section th {background: #f2f2f2;border:0}
.section-head {padding-top:10px; padding-bottom:10px}
.section-head span {display: block; float: left;}

.estimate-table input[type=number] {
    text-align:right;
  }
  .errorMsg{
    color: red;
    font-size: 14px;
  }
  #assumptions {.MTableToolbar-actions-8{
      position: absolute;
  }

}
.percentH{color: #0c1ff7}
.action-icons {width: 100%!important; float: none; text-align: right; padding-right: 0px}
.action-icons .change-color {display: none}
.action-icons span {min-width:20px; float:none; display: inline-block;}
.btnExport {position: absolute; right: 15px; top: 10px; font-weight: bold; background: #f2f2f2; padding: 5px 10px; border-radius: 5px; opacity: 0.7; border:0}
.btnExport:hover {opacity: 1}
.btnExport:focus {border:0; outline: none; opacity: 1}
.table.task .task-expand-collapse svg {visibility: hidden}
.usingf1Div input {margin-left: 5px; position: relative; top: 2px}
.task-space input {border: 1px solid #ccc; border-radius: 5px; padding:3px 5px}
.task-space input:disabled {visibility: hidden}
.task-total input, .task-space input {border: 1px solid #ccc; border-radius: 5px; padding:3px 5px;; width: 120px}
.smooth-dnd-container {margin-bottom: 10px}

#assumptions tr td .MuiTypography-h6 {font-size: 12px; color: #000; background:lightcoral; font-weight: normal; display: inline;; padding:2px 10px; border:1px dotted #f00}

}
